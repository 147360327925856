import { atom, selector } from "recoil";
import axios from "axios";

// used to swap between .dev and .work for development.
var extension = window.location.origin.endsWith('.work') ? '.work' : '.dev'

const endpoint = 'https://scriptman.sagebrush' + extension + '/scriptman_be/Admin'

/**
   * @typedef Log
   * @type {object}
   * @prop {string} timestamp timestamp of the log
   * @prop {string} log log message
   */

/**
 * @typedef ClientInfo
 * @type {object}
 * @prop {string} ID id of the client in the DB
 * @prop {string} Name the name of the client
 * @prop {string} LastConnect when the client last connected
 * @prop {string} Location the location of the client
 * @prop {string} IP the ip(s) of the client
 * @prop {string} Group the group the client is in
 * @prop {Array<Log>} ServerLogs the logs for the client on the server
 * @prop {Array<Log | TBD>} ClientLogs the logs of the client itself
 * @prop {string} Version the version of the client software currently running
 */

/**
 *  request a list of all the clients in the database
 * @returns {Array<ClientInfo>} list of all the clients
 */
export const clientsAtom = atom({
  key: 'clientsAtom',
  default: selector({
    key: 'clientsSelector',
    get: async () => {
      return await getListOfClients()
    }
  })
})

export const systemLogsAtom = atom({
  key: 'systemLogsAtom',
  default: selector({
    key: 'systemLogsSelector',
    get: async () => {
      return await getSystemLogs()
    }
  })
})

export const getListOfClients = async () => {
  return await axios({
    method: "get",
    url: endpoint + "/getListOfClientsInfo",
  })
    .then((msg) => {
      if (msg.status === 200) {
        return msg.data.sort(sortClientsByName);
      } else {
        return [];
      }
    })
    .catch((error) => {
      return [];
    });
}

export const sortClientsByName = (a, b) => {
  if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
  if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
  return 0;
}

/**
 * Request to the db to update/set the location of a client
 * @param {string} clientID the ID of the client in the DB
 * @param {string} update The name of the new location of the client
 */
export const updateClientLocation = (clientID, update) => {
  return axios({
    method: "post",
    url: endpoint + "/updateClientLocation",
    data: {
      ClientID: clientID,
      Update: update
    }
  })
}

/**
 * Request to the db to update/set the Group of a client
 * @param {string} clientID the ID of the client in the DB
 * @param {string} update The name of the new Group of the client
 */
export const updateClientGroups = (clientID, update) => {
  return axios({
    method: "post",
    url: endpoint + "/updateClientGroup",
    data: {
      ClientID: clientID,
      Update: update
    }
  })
}

export const deleteClient = async (clientID) => {
  return await axios({
    method: "post",
    url: endpoint + "/deleteClient",
    data: {
      id: clientID,
    }
  })
}

export const rebootClient = async (clientID) => {
  return await axios({
    method: "post",
    url: endpoint + "/rebootClient",
    data: {
      id: clientID,
    }
  }).then((msg) => {
    if (msg.data?.response) {
      alert(msg.data.response)
    }
  })
}

export const getSystemLogs = async () => {
  return await axios({
    method: 'get',
    url: endpoint + "/getSystemLogs",
  }).then((msg) => {
    return msg.data
  })
}

export const sortByLastConnect = (a, b) => {
  if (a.LastConnect < b.LastConnect) return -1;
  if (a.LastConnect > b.LastConnect) return 1;
  return 0;
}

export const getTimePast = (lastConnect) => {
  let seconds = (new Date() - new Date(lastConnect)) / 1000
  let minutes = seconds / 60
  let hours = minutes / 60
  let days = hours / 24
  let time = seconds
  let label = 'second'

  if (days >= 1) {
    time = days
    label = 'day'
  } else if (hours >= 1) {
    time = hours
    label = 'hour'
  } else if (minutes >= 1) {
    time = minutes
    label = 'minute'
  }
  time = parseInt(time)
  if (time > 1) {
    label += 's'
  }
  return `${time} ${label}`
}