import { atom, selector } from "recoil";
import axios from "axios";

// used to swap between .dev and .work for development.
var extension = window.location.origin.endsWith('.work') ? '.work' : '.dev'

const endpoint = 'https://scriptman.sagebrush' + extension + '/scriptman_be/Admin'

export const sortScriptsByName = (a, b) => {
  if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
  if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
  return 0;
}

/**
   * request a script to be deleted from the db
   * @param {String} scriptID the id of the script
   * @returns 
   */
export const deleteScript = (scriptID) => {
  return axios({
    method: "post",
    url: endpoint + "/deleteScript",
    data: {
      id: scriptID
    }
  })
}

/**
   * @typedef Script
   * @type {object}
   * @prop {String} id the id of the script in the db
   * @prop {String} Name the name of the script
   * @prop {String} Hash the hash of the script file
   * @prop {String} Path the location of the script
   * @prop {String} Extension the file extension of the script
   * @prop {String} Description a discription describing the script
   * @prop {Boolean} IsScriptInUse whether or not the script is being used on a schedule
   */

/**
 * request a list of all the scripts in the db
 * @returns {Array<Script>} list of scripts
 */
export const scriptsAtom = atom({
  key: 'scriptsAtom',
  default: selector({
    key: 'scriptsSelector',
    get: async () => {
      return await getListOfScripts()
    }
  })
})

export const getListOfScripts = async () => {
  return await axios({
    method: "get",
    url: endpoint + "/getListOfScripts",
  })
    .then((msg) => {
      if (msg.status === 200) {
        return msg.data.sort(sortScriptsByName);
      } else {
        return [];
      }
    })
    .catch((error) => {
      return [];
    });
}

/**
 * @typedef UploadScript
 * @type {object}
 * @prop {file} file the script file
 * @prop {String} name name to associate with the script
 * @prop {String} description description of what the script is for/does
 */
/**
 * upload a script to the db
 * @param {UploadScript} script the script to upload
 * @returns 
 */
export const uploadScript = (script) => {
  const formData = new FormData();
  formData.append("file", script.file, script.file.name)
  formData.append("name", script.name)
  formData.append("description", script.description)
  return axios({
    method: "post",
    url: endpoint + "/uploadScript",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData

  }).then((msg) => {
    if (msg.data !== 200) {
      return msg.data
    } else {
      return false
    }
  })
}

/**
 * @typedef EditScript
 * @type {object}
 * @prop {Number} scriptID id of the script in the db
 * @prop {file} file the script file
 * @prop {String} name name to associate with the script
 * @prop {String} description description of what the script is for/does
 */

/**
 * update an existing script and any schedules using it in the db
 * @param {EditScript} script the script to upload
 * @returns 
 */
export const editScript = (script) => {
  const formData = new FormData();
  if (script.file) {
    formData.append("file", script.file, script.file.name)
  }
  formData.append("name", script.name)
  formData.append("description", script.description)
  formData.append("scriptID", script.scriptID)
  return axios({
    method: "post",
    url: endpoint + "/editScript",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData
  }).then((msg) => {
    if (msg.data !== 200) {
      return msg.data
    } else {
      return false
    }
  })
}