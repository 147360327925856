import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';

import './scripts.css'
import '../../components/GenericPage/page.css'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import uploadingGif from './Uploading.gif'

import { scriptsAtom, uploadScript, editScript, deleteScript, getListOfScripts } from '../../states/scripts';
import { getListOfSchedules, schedulesAtom } from '../../states/scheduler';

import { useRecoilState } from 'recoil';
import update from "immutability-helper";

import Loading from '../../components/Loading/Loading';

library.add(fas)

const ScriptsComp = ({ setPageName }) => {

  const [scripts, setScripts] = useRecoilState(scriptsAtom)
  const [selectedOption, setSelectedOption] = useState('All')
  const [search, setSearch] = useState({
    name: '',
    desc: '',
    ext: ''
  })

  const emptyNewScript = {
    id: '',
    Name: '',
    Description: '',
    File: ''
  }

  const updateSearch = (val) => {
    setSearch(prev => {
      return update(prev, {
        $merge: val
      })
    })
  }

  const [scriptPopup, setScriptPopup] = useState(scripts.length === 0 ? true : false)
  const [schedules, setSchedules] = useRecoilState(schedulesAtom)
  const [selectedScript, setSelectedScript] = useState(emptyNewScript)

  const updateAtoms = async () => {
    setSchedules(await getListOfSchedules())
    setScripts(await getListOfScripts())
    return
  }

  const submitForm = async (values) => {
    var script
    if (values.id === '') {
      script = await uploadScript({
        file: values.File,
        name: values.Name,
        description: values.Description
      })
    } else {
      script = await editScript({
        scriptID: values.id,
        file: values.File,
        name: values.Name,
        description: values.Description
      })
    }
    if (script) {
      alert(`Script has already been uploaded, look at: ${script}`)
    }
    await updateAtoms()
    setSelectedScript(emptyNewScript)
    return
  }

  const handleDeleteScript = async (id) => {
    await deleteScript(id)
    await updateAtoms()
  }

  const filterScripts = (schs) => {
    if (selectedOption === 'In Use') {
      return schs.filter(item => item.IsScriptInUse)
    }

    if (selectedOption === 'Not In Use') {
      return schs.filter(item => !item.IsScriptInUse)
    }

    return schs
  }

  const selectOption = (option) => {
    if (option !== selectedOption) {
      setSelectedOption(option)
    }
  }

  const searchScripts = (schs) => {
    return schs.filter(s => s.Name.toLowerCase().includes(search.name) &&
      s.Extention.toLowerCase().includes(search.ext) &&
      s.Description.toLowerCase().includes(search.desc))
  }

  useEffect(() => {
    setPageName('Scripts')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className='pageBody' style={{ maxWidth: '1000px' }}>
        <div className='scriptOptionBar'>
          <div style={{ display: 'flex' }}>
            <ScriptOptionBarButton
              buttonStyle='scriptButton'
              text='All'
              setVal={selectOption}
              val={selectedOption}
            />
            <ScriptOptionBarButton
              buttonStyle='scriptButton'
              text='In Use'
              setVal={selectOption}
              val={selectedOption}
            />
            <ScriptOptionBarButton
              buttonStyle='scriptButton'
              text='Not In Use'
              setVal={selectOption}
              val={selectedOption}
            />
          </div>
          <div
            className='scriptButtonEnd'
            style={{ padding: '2px 5px', color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' }}
            onClick={() => {
              setSelectedScript(emptyNewScript)
              setScriptPopup(!scriptPopup)
            }}
          >
            <FontAwesomeIcon style={{ width: '15px' }} icon={scriptPopup ? "fa-solid fa-xmark" : "fa-solid fa-plus"} />
          </div>
        </div>
        <div className='scriptPageInterfacesScaling'>
          <ScriptPopup
            trigger={scriptPopup}
            setTrigger={setScriptPopup}
            script={selectedScript}
            submitForm={submitForm}
          />
          {scripts.length > 0 ?
            <div className='scriptListTableScaling'>
              <div className='scriptListTopbar'>
                <div
                  className='scriptListTopbarItem'
                  style={{ flex: 3 }}
                >
                  <p>Name</p>
                  <input
                    className='scriptListSearchField'
                    type="text"
                    placeholder='Search...'
                    onChange={(e) => updateSearch({ name: e.target.value.toLowerCase() })}
                  />
                </div>
                <div
                  className='scriptListTopbarItem'
                  style={{ flex: 5 }}
                >
                  <p>Description</p>
                  <input
                    className='scriptListSearchField'
                    type="text"
                    placeholder='Search...'
                    onChange={(e) => updateSearch({ desc: e.target.value.toLowerCase() })}
                  />
                </div>
                <div
                  className='scriptListTopbarItem'
                  style={{ flex: 1 }}
                >
                  <p>Ext</p>
                  <input
                    className='scriptListSearchField'
                    type="text"
                    placeholder='Search...'
                    onChange={(e) => updateSearch({ ext: e.target.value.toLowerCase() })}
                  />
                </div>
                <div
                  className='scriptListTopbarItem'
                  style={{ borderRight: 'none', width: '25px' }}
                >
                </div>
              </div>

              {searchScripts(filterScripts(scripts)).length > 0 ?
                <div className='scriptListBox'>
                  {searchScripts(filterScripts(scripts)).map((script, index) => (
                    <ScriptListEntry
                      key={script.id}
                      index={index}
                      script={script}
                      setScriptPopup={setScriptPopup}
                      setSelectedScript={setSelectedScript}
                      handleDeleteScript={handleDeleteScript}
                    />
                  ))}
                </div>
                :
                <p style={{ textAlign: 'center', color: '#dddddd', margin: '5px 0px' }}>No Scripts Matching Search.</p>
              }
            </div>
            :
            <p style={{ textAlign: 'center', color: '#dddddd', margin: '10px 0px' }}>No Scripts.</p>
          }
        </div>
      </div>
    </React.Fragment>
  )
}

const Scripts = ({ setPageName }) => {
  return (
    <React.Suspense fallback={<Loading color={'white'} />}>
      <ScriptsComp setPageName={setPageName} />
    </React.Suspense>
  )
}

export default Scripts

const ScriptOptionBarButton = ({ text, buttonStyle, setVal, val }) => {
  return (
    <div
      className={buttonStyle}
      style={text === val ? { color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' } : {}}
      onClick={() => setVal(text)}
    >
      {text}
    </div>
  )
}

const ScriptListEntry = ({ script, setScriptPopup, setSelectedScript, handleDeleteScript, index }) => {
  return (
    <div className='scriptListItem' style={index % 2 !== 0 ? { backgroundColor: '#161616' } : {}}>
      <div
        className='scriptListItemEntry'
        style={{ flex: 3 }}
      >
        <p style={{ padding: '5px 5px' }}>{script.Name}</p>
      </div>
      <div
        className='scriptListItemEntry'
        style={{ flex: 5 }}
      >
        <p style={{ padding: '5px 5px' }}>{script.Description}</p>
      </div>
      <div
        className='scriptListItemEntry'
        style={{ flex: 1 }}
      >
        <p style={{ padding: '5px 5px' }}>{script.Extention}</p>
      </div>
      <div
        className='scriptListItemEntry'
        style={{ borderRight: 'none', width: '25px' }}
      >
        <a className="scriptListItemEntryIcon" href={script.Path} download>
          <FontAwesomeIcon icon="fa-solid fa-download" />
        </a>
        <FontAwesomeIcon
          className='scriptListItemEntryIcon'
          icon="fa-solid fa-pen-to-square"
          onClick={async (e) => {
            setSelectedScript(script)
            await setScriptPopup(false)
            setScriptPopup(true)
          }}
        />
        {!script.IsScriptInUse ?
          <FontAwesomeIcon
            className='scriptListItemEntryIcon'
            icon="fa-solid fa-trash-can"
            onClick={(e) => handleDeleteScript(script.id)}
          />
          : <></>}
      </div>
    </div>
  )
}

const ScriptPopup = ({ trigger, setTrigger, submitForm, script }) => {
  const [uploading, setUploading] = useState(false)
  const initialValues = {
    ...script,
  }
  const validateFormData = (values) => {
    var errors = {}
    if (!(values.Name).trim()) {
      errors.Name = 'Required'
    }

    if (!(values.Description).trim()) {
      errors.Description = 'Required'
    }

    if (!(values.File) && values.id === '') {
      errors.File = 'Required'
    }

    return errors
  }
  return (
    <div style={{
      maxHeight: trigger ? '800px' : '0px',
      opacity: trigger ? 1 : 0,
      transition: 'all 1000ms ease',
      overflow: 'hidden',
      marginBottom: '10px',
      border: trigger ? '1px solid #dddddd' : 'none',
      backgroundColor: '#212121',
    }}>
      {trigger ?
        <div className='scriptPopup'>
          <div className='scriptPopUpBox'>
            <Formik
              initialValues={initialValues}
              validate={validateFormData}
              onSubmit={async (values, { resetForm }) => {
                setUploading(true)
                await submitForm(values)
                setUploading(false)
                if (values.id !== '') {
                  setTrigger(false)
                }
                resetForm()
              }}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form className='scriptPopupForm'>
                  {uploading ?
                    <React.Fragment>
                      <p>
                        <img src={uploadingGif} alt='Uploading...' />
                      </p>
                    </React.Fragment>
                    :
                    <>
                      <h3 className='scriptPopupHeader'>
                        {values.id !== '' ? 'Edit Script' : 'New Script'}
                      </h3>

                      <div className='scriptPopupFormGroups'>
                        <label style={{ paddingRight: '5px' }}>Name:</label>
                        <div>
                          <Field
                            className='scriptInputField'
                            type="text"
                            name="Name"
                          />
                          <ErrorMessage name="Name" render={msg => <ErrorMsgPopup msg={msg} />} />
                        </div>
                      </div>

                      <div className='scriptPopupFormGroups'>
                        <label style={{ paddingRight: '5px' }}>Description:</label>
                        <div>
                          <Field
                            className='scriptInputField'
                            type="text"
                            name="Description"
                          />
                          <ErrorMessage name="Description" render={msg => <ErrorMsgPopup msg={msg} />} />
                        </div>
                      </div>

                      <div className='scriptPopupFormGroups'>
                        <label style={{ paddingRight: '5px' }}>File:</label>
                        <div>
                          <input
                            className='scriptInputField'
                            type="file"
                            onChange={(e) => setFieldValue('File', e.currentTarget.files[0])}
                          />
                          {values.id !== '' ?
                            <div className='invalidPopupBubble'>
                              <p>*Leave Blank to Use Existing File.</p>
                            </div>
                            :
                            <ErrorMessage name="File" render={msg => <ErrorMsgPopup msg={msg} />} />
                          }
                        </div>
                      </div>

                      <button
                        className='scriptInputField'
                        type="submit"
                        disabled={isSubmitting}
                        style={{ cursor: 'pointer' }}
                      >
                        Submit
                      </button>
                    </>}

                </Form>
              )}

            </Formik>
          </div>
        </div>
        : <div style={{ height: '194px' }}></div>}
    </div>
  )
}

const ErrorMsgPopup = ({ msg }) => {
  return (
    <div className='invalidPopupBubble'>
      <p>*</p>
      <p>{msg}</p>
    </div>
  )
}