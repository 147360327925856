import { atom, selector, selectorFamily, useRecoilState } from "recoil";
import axios from "axios";
import Cookies from 'js-cookie';

// used to swap between .dev and .work for development.
var extension = window.location.origin.endsWith('.work') ? '.work' : '.dev'

const endpoint = 'https://scriptman.sagebrush' + extension + '/scriptman_be'

const adminGroup = 'internal-script-man-admin@sagebrush.church';


/**
   * @typedef User
   * @type {object}
   * @prop {String} email email address of the user
   * @prop {String} firstName the first name of the user
   * @prop {String} lastName last name of the user
   * @prop {String} avatar the src of the avatar of the user (from google)
   * @prop {Array<String>} groups the groups the user is a part of
   */

export const clearUserInfo = (returnLoc = null) => {
  sessionStorage.removeItem("scriptManGroups")
  sessionStorage.removeItem("scriptManUserData")
  Cookies.remove("scriptManJWT")
  window.location = '/auth'
  if (returnLoc) {
    sessionStorage.setItem('scriptManReturnLocation', returnLoc)
  } else {
    sessionStorage.removeItem('scriptManReturnLocation')
  }
}

export const setUserCredentials = async () => {
  return await axios({
    method: "get",
    url: endpoint + "/auth/ValidateUser",
  })
    .then((msg) => {
      if (msg.status === 200) {
        return msg.data
      } else {
        clearUserInfo()
      }
    })
    .catch((error) => {
      clearUserInfo()
    });
}

export const updateGroups = async () => {
  return await axios({
    method: "get",
    url: endpoint + "/auth/getUserGroups",
  }).then((msg) => {
    return msg.data;
  });
}

/**
 * function used to sign out of google.
 * @func
 */
export const scriptmanLogout = async () => {
  return await axios({
    method: "get",
    url: endpoint + "/auth/Logout",
  }).then((msg) => {
    clearUserInfo()
  }).catch((error) => {
    clearUserInfo()
  });
}

export const sendUserCred = async (credentialResponse) => {
  return await axios({
    method: "post",
    url: endpoint + "/auth/swap_token",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Requested-With": "XMLHttpRequest",
      "X-Google-OAuth2-Type": "client",
    },
    data: credentialResponse,
  }).then((msg) => {
    Cookies.remove("scriptManJWT")
    Cookies.set("scriptManJWT", `${msg.data.token_type} ${msg.data.access_token}`)
    return;
  });
};

export const groupsAtom = atom({
  key: "groups",
  default: JSON.parse(sessionStorage.getItem("scriptManGroups")) || [],
});

export const userAtom = atom({
  key: "user",
  default: JSON.parse(sessionStorage.getItem("scriptManUserData")) || null,
});

export const hasAdminAccess = () => {
  return JSON.parse(sessionStorage.getItem("scriptManGroups"))?.find(item => item === adminGroup) ? true : false || false
}