import React from 'react'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'

import './sidebar.css'
import { Link } from 'react-router-dom';

// add icons to the library
library.add(fas)

const Sidebar = ({ routes, toggleSidebar }) => {
  return (
    <div className='sidebarBox' style={toggleSidebar ? { width: '225px', padding: '10px' } : { width: '0px' }}>
      <div style={toggleSidebar ? { transition: 'all 400ms cubic-bezier(.7,-0.4,1,.99)', opacity: 1 } : { transition: 'all 200ms ease-out', opacity: 0 }}>
        {toggleSidebar && (
          <>
            <div className='titleHeaderBox'>
              <div className='titleHeaderIcon'>
                <FontAwesomeIcon icon="fa-solid fa-code" />
              </div>
              <div className='titleHeader'>
                Script Manager
              </div>
            </div>

            <div className='sidebarLinksBox'>
              {routes.map((route, index) => (
                <Link
                  className='sidebarPageLinks'
                  style={{ 'textDecoration': 'None' }}
                  to={route.link}
                  key={index}
                >
                  <h2 className='sidebarPageLinks'>{route.name}</h2>
                </Link>
              ))}
            </div>
          </>
        )}
      </div>


    </div>
  )
}

export default Sidebar