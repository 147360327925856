import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  schedulesAtom,
  deleteSchedule,
  addEvent,
  addRecurring,
  editSchedule,
  getListOfSchedules,
  runScheduleNow
} from '../../states/scheduler';

import { clientsAtom } from '../../states/client';
import { scriptsAtom, getListOfScripts } from '../../states/scripts';

import { useRecoilState, useRecoilValue } from 'recoil';
import update from "immutability-helper";

import './schedules.css'
import '../../components/GenericPage/page.css'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import FormikSelect from '../../components/Assets/FormikSelect';
import Loading from '../../components/Loading/Loading';
import LoadingBars from '../../components/Loading/LoadingBars';
// add icons to the library
library.add(fas)


const SchedulesComp = ({ setPageName }) => {

  const clients = useRecoilValue(clientsAtom)
  const [schedules, setSchedules] = useRecoilState(schedulesAtom)
  const [scripts, setScripts] = useRecoilState(scriptsAtom)

  const [selectedOption, setSelectedOption] = useState('All')

  const emptySchedule = {
    id: '',
    ScheduleType: 'Recurring',
    Name: '',
    Client: '',
    Start: '',
    End: '',
    DaysOfWeek: [],
    Script: '',
    Day: '0',
    Date: '',
    frequency: null
  }

  const [schedulePopup, setSchedulePopup] = useState(schedules.length === 0 ? true : false)
  const [selectedSchedule, setSelectedSchedule] = useState(emptySchedule)
  const [search, setSearch] = useState({
    name: '',
    client: '',
    script: '',
    when: ''
  })

  const updateAtoms = async () => {
    setSchedules(await getListOfSchedules())
    setScripts(await getListOfScripts())
    return
  }

  const submitForm = async (values) => {
    if (values.frequencyType === 'Once') {
      values.frequency = null
      values.frequencyType = undefined
    }
    if (values.RecurringInterval === 'Weekly') {
      values.Day = '0'
    } else {
      values.DaysOfWeek = []
    }
    var date = values.Date.split('-')
    if (date.length === 3) {
      values.Date = (date[1] + '/' + date[2] + '/' + date[0])
    }
    let schedule = {
      Name: values.Name,
      client: values.Client,
      Start: values.Start,
      End: values.End,
      Script: values.Script,
      frequency: values.frequency
    }

    if (values.ScheduleType === 'Event') {
      schedule.Date = values.Date
    } else {
      schedule.DaysOfWeek = values.DaysOfWeek
      schedule.Day = values.Day
    }

    if (Array.isArray(values.Client)) {
      let hold = values.Client[0]
      values.Client.map(async (item, index) => {
        if (index !== 0) {
          schedule.Client = item
          if (values.ScheduleType === 'Event') {
            await addEvent(schedule)
          } else {
            await addRecurring(schedule)
          }
        }
      })

      schedule.Client = hold
    }
    if (values.id === '') {
      if (values.ScheduleType === 'Event') {
        await addEvent(schedule)
      } else {
        await addRecurring(schedule)
      }
      await updateAtoms()
      if (values.clearOnSubmit) {
        setSelectedSchedule(emptySchedule)
      }
    } else {
      if (values.ScheduleType === 'Event') {
        values.Day = 0
      }
      values.Day = '' + values.Day
      if (Array.isArray(values.Client)) { values.Client = schedule.Client }
      handleEditSchedule(values)
    }
  }

  const handleDeleteSchedule = async (schedule) => {
    await deleteSchedule(schedule.ScheduleType, schedule.id)
    await updateAtoms()
  }

  const handleEditSchedule = async (schedule) => {
    await editSchedule(schedule)
    await updateAtoms()
  }

  const runSchedule = (schedule) => {
    runScheduleNow(schedule)
  }

  const selectOption = (option) => {
    if (option !== selectedOption) {
      setSelectedOption(option)
    }
  }

  const filterSchedules = (schs) => {
    if (selectedOption === 'Today') {
      return schs.filter(s => s.ActiveToday)
    } else if (selectedOption === 'Recurring' || selectedOption === 'Event') {
      return schs.filter(s => s.ScheduleType === selectedOption)

    } else {
      return schs
    }
  }

  const searchSchedules = (schs) => {
    return schs.filter(item => item.Name.toLowerCase().includes(search.name) &&
      item.Client.Name.toLowerCase().includes(search.client) &&
      item.Script.Name.toLowerCase().includes(search.script) &&
      item.When.join(' ').toLowerCase().includes(search.when))
  }

  const updateSearch = (val) => {
    setSearch(prev => {
      return update(prev, {
        $merge: val
      })
    })
  }

  useEffect(() => {
    setPageName('Schedules')
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    emptySchedule.Date = today
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div
        className='pageBody'
        style={{ maxWidth: '1200px' }}
      >
        <div className='scheduleStickyBox'>
          <div className='scheduleOptionBar'>
            <div style={{ display: 'flex' }}>
              <ScheduleOptionBarButton
                buttonStyle='scheduleButton'
                text='All'
                setVal={selectOption}
                val={selectedOption}
              />
              <ScheduleOptionBarButton
                buttonStyle='scheduleButton'
                text='Recurring'
                setVal={selectOption}
                val={selectedOption}
              />
              <ScheduleOptionBarButton
                buttonStyle='scheduleButton'
                text='Event'
                setVal={selectOption}
                val={selectedOption}
              />
              <ScheduleOptionBarButton
                buttonStyle='scheduleButtonEnd'
                text='Today'
                setVal={selectOption}
                val={selectedOption}
              />
            </div>
            <div
              className='scheduleButtonEnd'
              style={{ padding: '2px 5px', color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' }}
              onClick={() => {
                setSelectedSchedule(emptySchedule)
                setSchedulePopup(!schedulePopup)
              }}
            >
              <FontAwesomeIcon style={{ width: '15px' }} icon={schedulePopup ? "fa-solid fa-xmark" : "fa-solid fa-plus"} />
            </div>
          </div>
        </div>
        <div className='schedulePageInterfacesScaling'>
          <SchedulePopup
            trigger={schedulePopup}
            setTrigger={setSchedulePopup}
            schedule={selectedSchedule}
            submitForm={submitForm}
            clients={clients}
            scripts={scripts}
          />
          {schedules.length > 0 ?
            <div className='scheduleListTableScaling'>

              <div className='scheduleListTopbar'>
                <div
                  className='scheduleListTopbarItem'
                  style={{ flex: 3 }}
                >
                  <p>Name</p>
                  <input
                    className='scheduleListSearchField'
                    type="text"
                    placeholder='Search...'
                    onChange={(e) => updateSearch({ name: e.target.value.toLowerCase() })}
                  />
                </div>
                <div
                  className='scheduleListTopbarItem'
                  style={{ flex: 3 }}
                >
                  <p>Client</p>
                  <input
                    className='scheduleListSearchField'
                    type="text"
                    placeholder='Search...'
                    onChange={(e) => updateSearch({ client: e.target.value.toLowerCase() })}
                  />
                </div>
                <div
                  className='scheduleListTopbarItem'
                  style={{ flex: 3 }}
                >
                  <p>Script</p>
                  <input
                    className='scheduleListSearchField'
                    type="text"
                    placeholder='Search...'
                    onChange={(e) => updateSearch({ script: e.target.value.toLowerCase() })}
                  />
                </div>
                <div
                  className='scheduleListTopbarItem'
                  style={{ flex: 2 }}
                >
                  <p>When</p>
                  <input
                    className='scheduleListSearchField'
                    type="text"
                    placeholder='Search...'
                    onChange={(e) => updateSearch({ when: e.target.value.toLowerCase() })}
                  />
                </div>
                <div
                  className='scheduleListTopbarItem'
                  style={{ borderRight: 'none', width: '25px' }}
                >
                </div>
              </div>
              {searchSchedules(filterSchedules(schedules)).length > 0 ?
                <div className='scheduleListBox'>
                  {searchSchedules(filterSchedules(schedules)).map((schedule, index) => (
                    <ScheduleListEntry
                      key={schedule.id}
                      index={index}
                      schedule={schedule}
                      setSchedulePopup={setSchedulePopup}
                      setSelectedSchedule={setSelectedSchedule}
                      deleteSchedule={handleDeleteSchedule}
                      runSchedule={runSchedule}
                    />
                  ))}
                </div>
                :
                <p style={{ textAlign: 'center', color: '#dddddd', margin: '5px 0px' }}>No Schedules Matching Search.</p>
              }

            </div>
            :
            <p style={{ textAlign: 'center', color: '#dddddd', margin: '10px 0px' }}>No Schedules.</p>
          }
        </div>
      </div>
    </React.Fragment>
  )
}

const Schedules = ({ setPageName }) => {
  return (
    <React.Suspense fallback={<Loading color={'white'} />}>
      <SchedulesComp setPageName={setPageName} />
    </React.Suspense>
  )
}

const ScheduleListEntry = ({ schedule, setSchedulePopup, setSelectedSchedule, deleteSchedule, runSchedule, index }) => {
  const [toggleLoading, setToggleLoading] = useState(true)

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const runScheduleDelay = async (schedule) => {
    setToggleLoading(false)
    await runSchedule(schedule)
    await delay(500);
    setToggleLoading(true)
  }
  return (
    <div className='scheduleListItem' style={index % 2 !== 0 ? { backgroundColor: '#161616' } : {}}>
      <div
        className='scheduleListItemEntry'
        style={{ flex: 3 }}
      >
        <p style={{ padding: '5px 5px' }}>{schedule.Name}</p>
      </div>
      <div
        className='scheduleListItemEntry'
        style={{ flex: 3 }}
      >
        <p style={{ padding: '5px 5px' }}>{schedule.Client.Name}</p>
      </div>
      <div
        className='scheduleListItemEntry'
        style={{ flex: 3 }}
      >
        <p style={{ padding: '5px 5px' }}>{schedule.Script.Name}</p>
      </div>
      <div
        className='scheduleListItemEntry'
        style={{ flex: 2, lineBreak: 'auto' }}
      >
        {schedule.When.map((item, index) => (
          <p
            key={index}
            style={{ padding: '0px 5px' }}
          >
            {item}
          </p>
        ))}
      </div>
      <div
        className='scheduleListItemEntry'
        style={{ borderRight: 'none', width: '25px' }}
      >
        <FontAwesomeIcon
          className='scheduleListItemEntryIcon'
          icon="fa-solid fa-trash-can"
          onClick={(e) => deleteSchedule(schedule)}
        />
        <FontAwesomeIcon
          className='scheduleListItemEntryIcon'
          icon="fa-solid fa-pen-to-square"
          onClick={async (e) => {
            setSelectedSchedule(schedule)
            await setSchedulePopup(false)
            setSchedulePopup(true)
          }}
        />
        {toggleLoading ? <FontAwesomeIcon
          className='scheduleListItemEntryIcon'
          icon="fa-solid fa-play"
          onClick={(e) => runScheduleDelay(schedule)}
        />
          :
          <div style={{ display: 'flex', justifyContent: 'center', padding: '8px 0px' }}>
            <LoadingBars width='14px' />
          </div>
        }
      </div>
    </div >
  )
}

const ScheduleOptionBarButton = ({ text, buttonStyle, setVal, val }) => {
  return (
    <div
      className={buttonStyle}
      style={text === val ? { color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' } : {}}
      onClick={() => setVal(text)}
    >
      {text}
    </div>
  )
}

const SchedulePopup = ({ trigger, setTrigger, submitForm, schedule, clients, scripts }) => {
  const initialValues = {
    ...schedule,
    RecurringInterval: schedule.id === '' ? 'Weekly' : schedule.Day === '0' ? 'Weekly' : 'Monthly',
    clearOnSubmit: schedule.clearOnSubmit ? schedule.clearOnSubmit : true,
    frequencyType: schedule.frequency === null ? 'Once' : 'Multiple',
    frequency: schedule.frequency === null ? 0 : schedule.frequency
  }
  const validateFormData = (values) => {
    var errors = {}

    if (!values.ScheduleType) {
      errors.scheduleType = 'Required'
    }

    if (!(values.Name).trim()) {
      errors.Name = 'Required'
    }

    if (values.Client === '') {
      errors.Client = 'Required'
    }

    if (Array.isArray(values.Client)) {
      if (values.Client.length === 0) {
        errors.client = 'Required'
      }
    }

    if (!values.Start) {
      errors.Start = 'Required'
    } else if (!/^([01]\d|2[0-3]):([0-5]\d)/g.test(values.Start) && values.Start.length === 5) {
      errors.Start = 'Format must be hh:mm, in military time'
    }

    if (!values.End) {
      errors.End = 'Required'
    } else if (!/^([01]\d|2[0-3]):([0-5]\d)/g.test(values.End) && values.End.length !== 5) {
      errors.End = 'Format must be hh:mm, in military time'
    } else if (values.End < values.Start) {
      errors.End = 'End time must be larger than Start time'
    }

    if (values.Script === '') {
      errors.Script = 'Required'
    }

    if (values.RecurringInterval === 'Weekly' && values.ScheduleType === 'Recurring') {
      if (values.DaysOfWeek.length === 0) {
        errors.DaysOfWeek = 'Required'
      }
    }
    if (values.RecurringInterval === 'Monthly' && values.ScheduleType === 'Recurring') {
      if (values.Day === '' || values.Day === '0') {
        errors.Day = 'Must be a number from 1-31'
      } else if (values.Day > 31 || values.Day < 1) {
        errors.Day = 'Must be a number from 1-31'
      }
    }

    if (values.ScheduleType === 'Event') {
      if (!values.Date) {
        errors.Date = 'Required'
      } else {
        var date = values.Date.split('-')

        if (date.length !== 3) {
          errors.Date = 'Date Must be MM/DD/YYYY'
        } else if (date[1].length !== 2) {
          errors.Date = 'Months must match mm'
        } else if (date[2].length !== 2) {
          errors.Date = 'Days must match dd'
        } else if (date[0].length !== 4) {
          errors.Date = 'Year must match yyyy'
        }
      }
    }

    if (values.frequencyType === 'Multiple') {
      if (values.frequency < 1) {
        errors.frequency = 'Value must be at least 1'
      }
    }
    return errors
  }
  return (
    <div style={{
      maxHeight: trigger ? '800px' : '0px',
      opacity: trigger ? 1 : 0,
      transition: 'all 1000ms ease',
      overflow: 'hidden',
      marginBottom: '10px',
      border: trigger ? '1px solid #dddddd' : 'none',
      backgroundColor: '#212121',
    }}>
      {trigger ?
        <div className='schedulePopup'>
          <div className='schedulePopUpBox'>
            <Formik
              initialValues={initialValues}
              validate={validateFormData}
              onSubmit={async (values, { resetForm }) => {
                if (values.id !== '') { setTrigger(false) }
                await submitForm(values)

                if (values.clearOnSubmit) { resetForm() }
              }}
            >
              {({ isSubmitting, values }) => (
                <Form className='schedulePopupForm'>
                  <div style={{ padding: '0px 5px 5px 5px' }}>
                    <h3 className='schedulePopupHeader'>
                      {values.id !== '' ? 'Edit Schedule' : 'New Schedule'}
                    </h3>

                    <div className='schedulePopupFormGroups'>
                      <label style={{ paddingRight: '5px' }}>Name:</label>
                      <div>
                        <Field
                          className='scheduleInputField'
                          type="text"
                          name="Name"
                        />
                        <ErrorMessage name="Name" render={msg => <ErrorMsgPopup msg={msg} />} />
                      </div>
                    </div>

                    <div className='schedulePopupFormGroups'>
                      <label style={{ paddingRight: '5px' }}>Client:</label>
                      <div style={{ width: '300px' }}>
                        <Field
                          name='Client'
                          component={FormikSelect}
                          options={clients}
                          isMulti={true}
                        />
                        <ErrorMessage name="Client" render={msg => <ErrorMsgPopup msg={msg} />} />
                      </div>
                    </div>

                    <div className='schedulePopupFormGroups'>
                      <label style={{ paddingRight: '5px' }}>Script:</label>
                      <div style={{ width: '300px' }}>
                        <Field
                          name='Script'
                          component={FormikSelect}
                          options={scripts}
                        />
                        <ErrorMessage name="Script" render={msg => <ErrorMsgPopup msg={msg} />} />
                      </div>
                    </div>

                    <div className='schedulePopupFormGroups'>
                      <label style={{ paddingRight: '5px' }}>Schedule Type:</label>
                      <div>
                        <div style={{ display: 'flex' }}>
                          <Field
                            value='Recurring'
                            component={FormikButton}
                            name='ScheduleType'
                          />
                          <Field
                            value='Event'
                            component={FormikButton}
                            name='ScheduleType'
                          />
                        </div>
                        <ErrorMessage name="ScheduleType" render={msg => <ErrorMsgPopup msg={msg} />} />
                      </div>
                    </div>

                    {values.ScheduleType === 'Recurring' ?
                      <>
                        <div className='schedulePopupFormGroups'>
                          <label style={{ paddingRight: '5px' }}>Interval:</label>
                          <div>
                            <div style={{ display: 'flex' }}>
                              <Field
                                value='Weekly'
                                component={FormikButton}
                                name='RecurringInterval'
                              />
                              <Field
                                value='Monthly'
                                component={FormikButton}
                                name='RecurringInterval'
                              />
                            </div>
                            <ErrorMessage name="RecurringInterval" render={msg => <ErrorMsgPopup msg={msg} />} />
                          </div>
                        </div>
                        <div className='schedulePopupFormGroups'>
                          {values.RecurringInterval === 'Weekly' ?
                            <>
                              <label style={{ paddingRight: '5px' }}>Days:</label>
                              <div>
                                <Field
                                  component={FormikWeekSelection}
                                  name='DaysOfWeek'
                                />
                                <ErrorMessage name="DaysOfWeek" render={msg => <ErrorMsgPopup msg={msg} />} />
                              </div>
                            </> :
                            <>
                              <label style={{ paddingRight: '5px' }}>Day:</label>
                              <div>
                                <Field
                                  style={{ width: '35px' }}
                                  className='scheduleInputField'
                                  type="Number"
                                  name="Day"
                                />
                                <ErrorMessage name="Day" render={msg => <ErrorMsgPopup msg={msg} />} />
                              </div>
                            </>}
                        </div>
                      </>
                      :
                      <div className='schedulePopupFormGroups'>
                        <label style={{ paddingRight: '5px' }}>Date:</label>
                        <div>
                          <Field
                            className='scheduleInputField'
                            type="date"
                            name="Date"
                          />
                          <ErrorMessage name="Date" render={msg => <ErrorMsgPopup msg={msg} />} />
                        </div>
                      </div>}
                    <div className='schedulePopupFormGroups'>
                      <label style={{ paddingRight: '5px' }}>Start:</label>
                      <div>
                        <Field
                          className='scheduleInputField'
                          name='Start'
                          type='time'
                        />
                        <ErrorMessage name="Start" render={msg => <ErrorMsgPopup msg={msg} />} />
                      </div>
                    </div>

                    <div className='schedulePopupFormGroups'>
                      <label style={{ paddingRight: '5px' }}>End:</label>
                      <div>
                        <Field
                          className='scheduleInputField'
                          name='End'
                          type='time'

                        />
                        <ErrorMessage name="End" render={msg => <ErrorMsgPopup msg={msg} />} />
                      </div>
                    </div>

                    <div className='schedulePopupFormGroups'>
                      <label style={{ paddingRight: '5px' }}>Run:</label>
                      <div>
                        <div style={{ display: 'flex' }}>
                          <Field
                            value='Once'
                            component={FormikButton}
                            name='frequencyType'
                          />
                          <Field
                            value='Multiple'
                            component={FormikButton}
                            name='frequencyType'
                          />
                        </div>
                        <ErrorMessage name="frequencyType" render={msg => <ErrorMsgPopup msg={msg} />} />
                      </div>
                    </div>
                    {values.frequencyType === 'Multiple' && (
                      <div className='schedulePopupFormGroups'>
                        <label style={{ paddingRight: '5px' }}>Every:</label>
                        <div>
                          <Field
                            style={{ width: '35px', marginRight: '5px' }}
                            className='scheduleInputField'
                            type="Number"
                            name="frequency"
                          />
                          <span>min</span>
                          <ErrorMessage name="frequency" render={msg => <ErrorMsgPopup msg={msg} />} />
                        </div>
                      </div>
                    )}

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <button
                        className='scheduleInputField'
                        type="submit"
                        disabled={isSubmitting}
                        style={{ cursor: 'pointer' }}
                      >
                        {isSubmitting ?
                          <div style={{ textAlign: 'center', width: '46px' }}><LoadingBars /></div>
                          :
                          'Submit'
                        }
                      </button>
                      {values.id === '' && (
                        <>
                          <Field
                            type='checkbox'
                            name='clearOnSubmit'
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                          />
                          <p style={{ fontWeight: 'normal', fontSize: '12px', marginLeft: '3px' }}>Clear On Submit</p>
                        </>
                      )}
                    </div>
                  </div>
                </Form>
              )}

            </Formik>
          </div>
        </div>
        : <div style={{ height: '403px' }}></div>}
    </div>
  )
}

const FormikWeekSelection = ({ field, form }) => {
  const dayOfWeek = [
    'Sunday', 'Monday', 'Tuesday',
    'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ]
  const selectDay = (day) => {
    var values = [...field.value]
    if (values.includes(day)) {
      values = values.filter(e => e !== day)
    } else {
      values.push(day)
      values.sort(function sortByDay(a, b) {
        return dayOfWeek.indexOf(a) - dayOfWeek.indexOf(b)
      })
    }
    form.setFieldValue(field.name, values)
  }
  const daySelected = (day) => {
    return field.value.includes(day)
  }
  const createButton = (value, buttonStyle) => {
    return <div
      key={value}
      className={buttonStyle}
      style={daySelected(dayOfWeek[value]) ? { color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' } : {}}
      onClick={() => selectDay(dayOfWeek[value])}
    >
      {dayOfWeek[value].substring(0, 2)}
    </div>
  }
  return (
    <div style={{ display: 'flex' }}>
      {dayOfWeek.map((day, index) => (
        index === dayOfWeek.length - 1 ? createButton(index, 'scheduleButtonEnd') : createButton(index, 'scheduleButton')
      ))}
    </div>
  )
}

const FormikButton = ({ value, field, form }) => {
  return (
    <div
      className='scheduleButton'
      style=
      {value === field.value ?
        { color: '#ffffff', backgroundColor: '#303030', border: '1px solid #3aa3f5' } :
        {}}
      onClick={() => form.setFieldValue(field.name, value)}
    >
      {value}
    </div>
  )
}

const ErrorMsgPopup = ({ msg }) => {
  return (
    <div className='invalidPopupBubble'>
      <p>*</p>
      <p>{msg}</p>
    </div>
  )
}

export default Schedules