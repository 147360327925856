import React, { useEffect, useState, useRef } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil';
import {
  getListOfClients,
  getSystemLogs,
  clientsAtom,
  systemLogsAtom,
  sortByLastConnect,
  getTimePast
} from '../../states/client';

import { schedulesAtom, sortSchedulesByTime } from '../../states/scheduler';
import Loading from '../../components/Loading/Loading';

import useWindowDimensions from '../../components/Assets/useWindowDimensions';

import '../../components/GenericPage/page.css'
import './dashboard.css'

const DashboardComp = ({ setPageName, toggleSidebar }) => {
  const [clients, setClients] = useRecoilState(clientsAtom)
  const [systemLogs, setSystemLogs] = useRecoilState(systemLogsAtom)
  const activeSchedules = useRecoilValue(schedulesAtom).filter(item => item.ActiveToday)

  const [toggleTimestamp, setToggleTimestamp] = useState(true)
  const [toggleRefresh, setToggleRefresg] = useState(false)

  const { height, width } = useWindowDimensions()

  const adjustedWidth = toggleSidebar ? width - 245 : width

  const requiredWidth = 650

  const boxSize = adjustedWidth > requiredWidth ? 'calc(50% - 24px)' : 'calc(100% - 24px)'

  const updateAtoms = async () => {
    setClients(await getListOfClients())
    if (toggleRefresh) { setSystemLogs(await getSystemLogs()) }
  }

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView({ block: "nearest", inline: "nearest" }));
    return <div ref={elementRef} />;
  };

  useEffect(() => {
    setPageName('Dashboard')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateVal = () => {
    if (val === 0) {
      setVal(1)
    } else {
      setVal(0)
    }
  }

  const [val, setVal] = useState(0)

  useEffect(() => {
    const timer = setTimeout(updateVal, 1000 * 60 * 3) // update every 3 min
    updateAtoms()
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  return (
    <React.Fragment>
      <div className='pageBody' style={{ overflow: 'auto', height: 'calc(100vh - 80px)', margin: '0px' }}>
        <div style={{
          display: 'flex',
          flexDirection: adjustedWidth > requiredWidth ? 'row' : 'column',
          flexWrap: 'wrap',
          margin: toggleSidebar || adjustedWidth > requiredWidth ? '0px 10px' : '0px'
        }}>
          <div className='dashboardInfoBox' style={{ width: boxSize }} >
            <div
              className='dashboardInfoHeaderBox'
              style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <span>System Logs:</span>
              <div style={{ display: 'flex', fontSize: '12px' }}>
                <input
                  style={{ cursor: 'pointer' }}
                  checked={toggleRefresh}
                  onChange={(e) => setToggleRefresg(e.target.checked)}
                  type='checkbox'
                />
                <p style={{ margin: '3px 5px 0px 2px' }}>Refresh</p>
                <input
                  checked={toggleTimestamp}
                  style={{ cursor: 'pointer' }}
                  onChange={(e) => setToggleTimestamp(e.target.checked)}
                  type='checkbox'
                />
                <p style={{ margin: '3px 5px 0px 2px' }}>Timestamps</p>
              </div>
            </div>
            <div className='dashboardInfoBodyBox'>
              {systemLogs.map((log) => {
                return (
                  <div key={log._id} style={{ padding: '0px 3px' }}>
                    <p>{toggleTimestamp &&
                      (<span style={{ color: '#3aa3f5' }}>{log.timestamp}: </span>)}
                      {log.log}
                    </p>
                  </div>
                )
              })}
              {toggleRefresh && (<AlwaysScrollToBottom />)}
            </div>
          </div>
          <div className='dashboardInfoBox' style={{ width: boxSize }}>
            <div className='dashboardInfoHeaderBox'>
              <span>Today's Schedules:</span>
            </div>
            <div className='dashboardInfoBodyBox'>
              {[...activeSchedules].sort(sortSchedulesByTime).map((item, index) => (
                <ScheduleEntry key={item.id} index={index} schedule={item} />
              ))}
            </div>
          </div>
          <div className='dashboardInfoBox' style={{ width: boxSize }}>
            <div className='dashboardInfoHeaderBox'>
              <span>Clients:</span>
            </div>
            <div className='dashboardInfoBodyBox'>
              {[...clients].sort(sortByLastConnect).map((item, index) => (
                <ClientEntry key={item.id} index={index} client={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>

  )
}

const ScheduleEntry = ({ schedule, index }) => {
  return (
    <div className='dashboardInfoScheduleEntry' style={index % 2 !== 0 ? { backgroundColor: '#262626' } : {}}>
      <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{schedule.Name}</span>
      <div style={{ display: 'flex' }}>
        <span style={{ flex: '1' }}><span style={{ color: '#3aa3f5' }}>Client: </span>{schedule.Client.Name}</span>
        <span style={{ flex: '1' }}><span style={{ color: '#3aa3f5' }}>Script: </span>{schedule.Script.Name}</span>
      </div>
      <span><span style={{ color: '#3aa3f5' }}>Time: </span>{schedule.When[1]}</span>
    </div>
  )
}

const ClientEntry = ({ client, index }) => {
  return (
    <div className='dashboardInfoScheduleEntry' style={index % 2 !== 0 ? { backgroundColor: '#262626' } : {}} onClick={() => { window.location.href = `/Clients?${client.id}` }}>
      <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{client.Name}</span>
      <div style={{ display: 'flex' }}>
        <span style={{ flex: '1' }}><span style={{ color: '#3aa3f5' }}>IP: </span>{client.IP.split(' ')[0]}</span>
        <span style={{ flex: '1' }}><span style={{ color: '#3aa3f5' }}>Last Connected: </span>{getTimePast(client.LastConnect)}</span>
      </div>
    </div>
  )
}

const Dashboard = ({ setPageName, toggleSidebar }) => {
  return (
    <React.Suspense fallback={< Loading color={'white'} />}>
      <DashboardComp setPageName={setPageName} toggleSidebar={toggleSidebar} />
    </React.Suspense >
  )
}

export default Dashboard