import { atom, selector } from "recoil";
import axios from "axios";

// used to swap between .dev and .work for development.
var extension = window.location.origin.endsWith('.work') ? '.work' : '.dev'

const endpoint = 'https://scriptman.sagebrush' + extension + '/scriptman_be/Admin'

/**
   * request the api to remove a schedule from the database
   * @param {string} schType Recurring or Event Schedule
   * @param {string} schID The ID of the schedule in the DB
   * @func
   */
export const deleteSchedule = (schType, schID) => {
  return axios({
    method: "post",
    url: endpoint + "/deleteSchedule",
    data: {
      ScheduleType: schType,
      ScheduleID: schID
    }
  })
}

/**
   * @typedef AddRecurring
   * @type {Object}
   * @prop {String} Name the name of the recurring schedule
   * @prop {NameID} Client the id and name of the client the schedule is for
   * @prop {String} Start when the schedule starts (time)
   * @prop {String} End when the schedules ends (time)
   * @prop {Array<String>} DaysOfWeek which days of the week the recurring will fall on
   * @prop {NameID} Script the id and name of the script that will run
   * @prop {int} Day the day of the month the script will run (0 if days of the week are selected)
   */

/**
 * submit a recurring schedule to be added to the db
 * @param {AddRecurring} sch the schedule information
 * @returns
 */
export const addRecurring = async (sch) => {
  return await axios({
    method: "post",
    url: endpoint + "/addRecurring",
    data: sch
  })
}

/**
 * @typedef AddEvent
 * @type {Object}
 * @prop {String} Name the name of the recurring schedule
 * @prop {NameID} Client the id and name of the client the schedule is for
 * @prop {String} Start when the schedule starts (time)
 * @prop {String} End when the schedules ends (time)
 * @prop {String} Date the date the script will run on
 * @prop {NameID} Script the id and name of the script that will run
 */

/**
 * submit a event schedule to be added to the db
 * @param {AddEvent} sch the schedule information
 * @returns
 */
export const addEvent = async (sch) => {
  return await axios({
    method: "post",
    url: endpoint + "/addEvent",
    data: sch
  })
}

/**
 * @typedef NameID
 * @type {object}
 * @prop {String} id
 * @prop {String} Name
 */

/**
 * @typedef Schedule
 * @type {Object}
 * @prop {String} id the id of the schedule in the db
 * @prop {String} ScheduleType the schedule type
 * @prop {String} Name the name of the schedule
 * @prop {NameID} Client the id and name of the client the schedule is for
 * @prop {String} Start when the schedule start time is
 * @prop {String} End when the schedule end time is
 * @prop {Array<String>} DaysOfWeek days of the week the schedule falls on or empty
 * @prop {NameID} Script the id and name of the script that will run
 * @prop {String} Day the day of the month the schedule falls on or 0
 * @prop {String} Date the date the schedule falls on or empty
 * @prop {Boolean} ActiveToday [Optional] whether or not the schedule will occur today
 */

/**
 * edit a schedule that is in the DB
 * @param {Schedule} schedule
 * @returns
 */
export const editSchedule = (schedule) => {
  return axios({
    method: "post",
    url: endpoint + "/editSchedule",
    data: schedule
  })
}



/**
 * request a list of all the schedules in the db
 * @returns {Array<Schedule>} list of schedules
 */
export const schedulesAtom = atom({
  key: 'schedulesAtom',
  default: selector({
    key: 'schedulesSelector',
    get: async () => {
      return await getListOfSchedules()
    }
  })
})

export const runScheduleNow = async (schedule) => {
  return await axios({
    method: "post",
    url: endpoint + "/runScheduleNow",
    data: {
      ClientID: schedule.Client.id,
      ScriptID: schedule.Script.id,
      ScheduleID: schedule.id
    }
  }).then((msg) => {
    if (msg.data?.response) {
      alert(msg.data.response)
    }
  })
}

export const getListOfSchedules = async () => {
  return await axios({
    method: "get",
    url: endpoint + "/getListOfSchedules",
  })
    .then((msg) => {
      if (msg.status === 200) {
        let schedules = msg.data.sort(sortSchedulesByClient)
        schedules.forEach((item) => (
          item.When = getScheduleWhen(item)
        ))
        return schedules

      } else {
        return [];
      }
    })
    .catch((error) => {
      return [];
    });
}

const set24to12Hour = (time) => {
  if (time === 12 || time === 24) {
    return 12
  }
  return time % 12
}

const amOrpm = (time) => {
  if (time === 24) {
    return ' AM'
  } else if (time > 11) {
    return ' PM'
  } else {
    return ' AM'
  }
}

export const getScheduleWhen = (schedule) => {
  var start = schedule.Start.split(':')
  var end = schedule.End.split(':')

  var startTime = set24to12Hour(parseInt(start[0])) + ':' + start[1] + amOrpm(parseInt(start[0]))
  var endTime = set24to12Hour(parseInt(end[0])) + ':' + end[1] + amOrpm(parseInt(end[0]))
  var timeRange = startTime + ' - ' + endTime
  var when = ['Broken Date']
  if (schedule.Date !== '') {
    var date = schedule.Date.split('-')

    when = [(date[1] + ' / ' + date[2] + ' / ' + date[0]), timeRange]
  } else if (schedule.DaysOfWeek.length !== 0) {
    var days = ''
    schedule.DaysOfWeek.forEach((d, index) => {
      days += d.substring(0, 3)

      if (index < schedule.DaysOfWeek.length - 1) {
        days += ', '
      }
    });
    when = [days, timeRange]
  } else if (schedule.Day !== '0') {
    var ending = ''
    if (+schedule.Day < 11 || +schedule.Day > 13) {
      switch ((+schedule.Day) % 10) {
        case 1:
          ending = 'st'
          break;
        case 2:
          ending = 'nd'
          break;
        case 3:
          ending = 'rd'
          break;
        default:
          ending = 'th'
      }
    } else {
      ending = 'th'
    }
    when = [(schedule.Day + ending + ' of Every Month'), timeRange]
  }

  if (schedule.frequency !== null) {
    when.push(`Every ${schedule.frequency} min`)
  }
  return when
}

export const sortSchedulesByClient = (a, b) => {
  if (a.Client.Name.toLowerCase() < b.Client.Name.toLowerCase()) return -1;
  if (a.Client.Name.toLowerCase() > b.Client.Name.toLowerCase()) return 1;
  return 0;
}

export const sortSchedulesByTime = (a, b) => {
  if (a.Start < b.Start) return -1;
  if (a.Start > b.Start) return 1;
  return 0;
}