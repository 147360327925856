import Topbar from './components/topbar/Topbar';
import Sidebar from './components/sidebar/Sidebar';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useState, useEffect } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Dashboard from './views/Dashboard/Dashboard';
import Scripts from './views/Scripts/Scripts';
import Clients from './views/Clients/Clients';
import Schedules from './views/Schedules/Schedules';
import './App.css';

import AxiosInterceptor from './components/Assets/AxiosInterceptor';
import LoginPage from './views/Login/LoginPage';
import { hasAdminAccess } from './states/auth';

function App() {

  const AdminRoutes = [
    {
      link: '/',
      name: 'Dashboard'
    },
    {
      link: '/Schedules',
      name: 'Schedules'
    },
    {
      link: '/Scripts',
      name: 'Scripts'
    },
    {
      link: '/Clients',
      name: 'Clients'
    }
  ]

  const [toggleSidebar, setToggleSidebar] = useState(true)
  const [currentPageName, setCurrentPageName] = useState('')
  const admin = hasAdminAccess()
  const routes = admin ? AdminRoutes : []


  const CLIENT_ID = "6403420056-u1j7depe20k9qal8u08pk5u5jiqo06rd.apps.googleusercontent.com";

  useEffect(() => {
    let loc = window.location.pathname
    if (!admin && loc !== '/auth') {
      window.location = '/auth'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <AxiosInterceptor />
      <Router>
        <div className='pageBackground'>
          <div className='fullPage'>
            <Sidebar routes={routes} toggleSidebar={toggleSidebar} />
            <div className='webpage'>
              <Topbar
                setToggleSidebar={setToggleSidebar}
                pageName={currentPageName}
              />
              <div style={{ display: 'flex' }}>
                <Routes>
                  <Route path='/auth' element={<LoginPage />} />
                  {admin ? (<>
                    <Route path='/' element={<Dashboard setPageName={setCurrentPageName} toggleSidebar={toggleSidebar} />} />
                    <Route path='*' element={<Dashboard setPageName={setCurrentPageName} toggleSidebar={toggleSidebar} />} />
                    <Route path='/Schedules' element={<Schedules setPageName={setCurrentPageName} />} />
                    <Route path='/Scripts' element={<Scripts setPageName={setCurrentPageName} />} />
                    <Route path='/Clients' element={<Clients setPageName={setCurrentPageName} />} />
                  </>) : <>
                    <Route path='*' element={<LoginPage />} />
                  </>
                  }

                </Routes>
              </div>
            </div>
          </div>
        </div>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
