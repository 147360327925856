import React from "react";
import "./topbar.css";
import { scriptmanLogout, userAtom } from "../../states/auth";
import { useRecoilValue } from "recoil";
/**
 * @typedef UserInfo
 * @type {object}
 * @prop {String} email email address of the user
 * @prop {String} firstName the first name of the user
 * @prop {String} lastName last name of the user
 * @prop {String} avatar the src of the avatar of the user (from google)
 * @prop {Array<String>} groups the groups the user is a part of
 * @prop {String} Authorized the authorization level of the user
 */
/**
 * Create the login button for signing in and logging out of google.
 * @param {object} props
 * @prop {function} validateUser function used to validate the user access upon login
 * @prop {UserInfo} userInfo the users info that is set by validateUser 
 * @component
 */
const GoogleLogoutButton = () => {

  const userInfo = useRecoilValue(userAtom)

  /**
   * function used to sign out of google.
   * @func
   */
  const logout = async () => {
    await scriptmanLogout()
  }
  return (
    <>
      {userInfo && (
        <div
          className='googleLogoutButton'
          onClick={(e) => {
            logout()
          }}
        >
          <img
            style={{
              objectFit: 'contain',
              maxHeight: '25px',
              maxWidth: '25px'
            }}
            src={userInfo.avatar}
            title=""
            alt=""
          />
          <label className='googleLogoutText'> Logout</label>
        </div>
      )}
    </>
  );
};

export default GoogleLogoutButton;
